/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Route, Switch, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { AppTopbar } from "../../layouts/AppTopbar";
import { AppFooter } from "../../layouts/AppFooter";
import { AppMenu } from "../../layouts/AppMenu";
import { AppConfig } from "../../layouts/AppConfig";

import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";

import Dashboard from "./dashboards";
import Empty from "./empty";
import Users from "./users";
import { MasterContext } from "contexts/MasterContext";
import { useContext } from "react";
import Kycs from "./kycs";
import Verifies from "./verifies";
import Uploads from "./uploads";
import Supports from "./supports";
import Positions from "./settings/positions";
import TransactionInvestments from "./transactions/investments";
import TransactionProfitInvestments from "./transactions/profit-investments";
import TransactionChanges from "./transactions/changes";
import TransactionSwaps from "./transactions/swaps";
import TransactionDeposits from "./transactions/deposits";
import TransactionWithdraws from "./transactions/withdraws";
import SettingObjects from "./user-atributes/objects";
import Stars from "./post-setting/stars";
import StocksRequests from "./requests";
import Stocks from "./stocks";
import Notifications from "./notifications";
import Allotments from "./allotments";
import Products from "./manager-shop/products";
import BanksAndWallets from "./settings/banks";
import StocksPrice from "./settings/stocks-price";
import Teams from "./settings/teams";
import Donors from "./settings/donors";
import ActionPosts from "./post-setting/action-posts";
import Hobbies from "./user-atributes/hobbies";
import PostBackgrounds from "./post-setting/post-backgrounds";
import Stickers from "./post-setting/stickers";
import Utilities from "./settings/utilities";
import Logos from "./settings/logos";
import Channels from "./settings/channels";
import Affiliates from "./settings/affiliates";
import LandingPages from "./settings/landing-pages";
import PaymentDeposits from "./payments/deposits";
import PaymentWithdraws from "./payments/withdraws";
import Packages from "./packages";
import Campaigns from "./campaigns";
import Banners from "./filters/banners";
import QuestionAndAnswer from "./q&a";
import WhitePaper from "./white-paper";
import Policy from "./policy";
import MailTemplate from "./mail-templates";
import PageBackgrounds from "./filters/page-backgrounds";
import Locale from "./locales";
import SettingLanguage from "./settings/languages";
import SettingMenuCategory from "./settings/menu-categories";
import Menus from "./menus";
import Companions from "./settings/companions";
import Events from "./events";
import CategoryField from "./user-atributes/category-field";
import IconSet from "./settings/icon-set";
import Religion from "./user-atributes/religions";
import CategoryPost from "./post-setting/category-posts";
import Frames from "./post-setting/frames";
import Vip from "./settings/vips";
import Nation from "./user-atributes/nations";
import SettingUtilityCategory from "./settings/utility-categories";
import MenusAdmin from "./settings/menus-admin";
import SpecialName from "./settings/special-name";
import NotificationTemplate from "./notification-templates";
import CategoryFaqs from "./faqs";
import FormTemplate from "./form-templates";
import Quotas from "./filters/quotas";
import Tasks from "./tasks";
import Categories from "./categories";
import CategoriesByGroup from "./categories-by-group";
import CategoriesGroups from "./categories-groups";
import Locations from "./settings/locations";
import BusinessType from "./business-type";
import CategoryFilters from "./filters/categories-filters";
import ImportCodeProduct from "./manager-shop/products/components/ImportCode";
import Posts from "./posts";
import MenuCensor from "./settings/menus-censor";
import PostSettings from "./settings/posts";
import UsersVirtual from "./users-virtual";
import PostComments from "./post-comments";
import GenerateInfoVirtualUser from "./generate-info-virtual-user";
import InvoiceSettings from "./settings/invoice";
import UserInvoices from "./user-invoices";
import CensorVerify from "./censor-verify";
import Participants from "./participants";
import Reports from "./reports";
import PostsAds from "./settings/posts-ads";
import Loans from "./loans";
import Shops from "./manager-shop/shops";
import DigitalAffiliateBankSettings from "./digital-affiliate-bank/bank-settings";
import UserBanks from "./digital-affiliate-bank/bank-users";
import Digitals from "./manager-shop/digitals";
import Codes from "./codes";
import AttributeSet from "./attribute-set";
import BanWords from "./ban-word";
import Careers from "./user-atributes/careers";
import Families from "./user-atributes/families";
import SettingRoles from "./settings/roles";
import ProfileBackgrounds from "./post-setting/profile-backgrounds";
import Templates from "./post-setting/templates";
import PostSettingInteract from "./post-setting/stars/by-type";
import Fonts from "./fonts";
import ShopSetting from "./manager-shop/shop-setting";
import QuestionAndAnswerS from "./question-and-answer";
import PostWelcomeTemplates from "./post-welcome-templates";
import ProductDonate from "./settings/product-donate";
import SettingTooltip from "./settings/tooltip";
import Ships from "./ships";
import Captcha from "./settings/captcha";
import ApplyCaptcha from "./apply-captcha";
import Sites from "./settings/sites";
import RegisterReceiveInfo from "./register-receive-info";
import activities from "./activities";
import ThemeStyles from "./theme-styles";
import AdvPrice from "./adv-price";
import UsersByType from "./users-by-type";

const App = () => {
  const [layoutMode, setLayoutMode] = useState("static");
  const [layoutColorMode, setLayoutColorMode] = useState("light");
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const copyTooltipRef: any = useRef();
  const location = useLocation();
  const {
    auth,
    getStocks,
    getUsers,
    getNotifyPending,
    getObjects,
    getLanguages,
    getCareers,
    getLocations,
    getMenus,
    menus,
    getNations,
    getReligions,
    getCategoryFields,
    getAttributeSets,
    getFamilies,
    getMinigames,
    getAges,
    getCategories,
    getRoles,
    getCaptchaQuestion,
    getSites,
    getProductCategories,
  } = useContext(MasterContext);

  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    if (auth) {
      getCareers();
      getUsers();
      getStocks();
      getObjects();
      getLanguages();
      getLocations();
      getMenus(auth._id);
      getNations();
      getReligions();
      getCategoryFields();
      getAttributeSets();
      getFamilies();
      getMinigames();
      getAges();
      getCategories();
      getRoles();
      getCaptchaQuestion();
      getSites();
      getProductCategories();
    }
  }, [auth]);

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    getNotifyPending();

    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  const onInputStyleChange = (inputStyle: any) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e: any) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onLayoutModeChange = (mode: React.SetStateAction<string>) => {
    setLayoutMode(mode);
  };

  const onColorModeChange = (mode: React.SetStateAction<string>) => {
    setLayoutColorMode(mode);
  };

  const onWrapperClick = (event: any) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event: { preventDefault: () => void }) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === "overlay") {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event: { preventDefault: () => void }) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event: {
    preventDefault: () => void;
  }) => {
    mobileTopbarMenuClick = true;
    event.preventDefault();
  };

  const onMenuItemClick = (event: { item: { items: any } }) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };

  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const addClass = (element: HTMLElement, className: string) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element: HTMLElement, className: string) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive":
      staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active":
      overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
    "layout-theme-light": layoutColorMode === "light",
  });

  return (
    <div className={wrapperClass} onClick={onWrapperClick}>
      <Tooltip
        ref={copyTooltipRef}
        target=".block-action-copy"
        position="bottom"
        content="Copied to clipboard"
        event="focus"
      />

      <AppTopbar
        onToggleMenuClick={onToggleMenuClick}
        layoutColorMode={layoutColorMode}
        mobileTopbarMenuActive={mobileTopbarMenuActive}
        onMobileTopbarMenuClick={onMobileTopbarMenuClick}
        onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
      />

      <div className="layout-sidebar" onClick={onSidebarClick}>
        <AppMenu
          model={menus}
          onMenuItemClick={onMenuItemClick}
          layoutColorMode={layoutColorMode}
        />
      </div>

      <div className="layout-main-container">
        <div className="layout-main">
          <Switch>
            <Route
              path="/"
              exact
              render={() => (
                <Dashboard colorMode={layoutColorMode} location={location} />
              )}
            />
            <Route path="/users" component={Users} />
            <Route path="/users-virtual" component={UsersVirtual} />
            <Route
              path="/generate-info-virtual-user"
              component={GenerateInfoVirtualUser}
            />
            <Route path="/censor-verify" component={CensorVerify} />
            <Route path="/kycs" component={Kycs} />
            <Route path="/invoices" component={UserInvoices} />
            <Route path="/verifies" component={Verifies} />
            <Route path="/uploads" component={Uploads} />
            <Route path="/banners" component={Banners} />
            <Route path="/supports" component={Supports} />
            <Route path="/support/:type" component={Supports} />
            <Route path="/requests" component={StocksRequests} />
            <Route path="/stocks" component={Stocks} />
            <Route path="/events" component={Events} />
            <Route path="/notifications" component={Notifications} />
            <Route path="/allotments" component={Allotments} />
            <Route path="/products/codes/:id" component={ImportCodeProduct} />
            <Route path="/products" component={Products} />
            <Route path="/qa" component={QuestionAndAnswer} />
            <Route path="/whitepaper" component={WhitePaper} />
            <Route path="/policy" component={Policy} />
            <Route path="/mail-templates" component={MailTemplate} />
            <Route path="/category-faqs" component={CategoryFaqs} />
            <Route path="/form-templates" component={FormTemplate} />
            <Route path="/fonts" component={Fonts} />
            <Route path="/activities" component={activities} />
            <Route path="/posts" component={Posts} />
            <Route path="/loans" component={Loans} />
            <Route
              path="/notification-templates"
              component={NotificationTemplate}
            />
            <Route path="/locales" component={Locale} />
            <Route path="/menus" component={Menus} />
            <Route path="/categories" component={Categories} />
            <Route
              path="/categories-by-group/:group"
              component={CategoriesByGroup}
            />
            <Route path="/categories-groups" component={CategoriesGroups} />

            <Route
              path="/transactions/investments"
              component={TransactionInvestments}
            />
            <Route
              path="/transactions/profit-investments"
              component={TransactionProfitInvestments}
            />
            <Route
              path="/transactions/deposits"
              component={TransactionDeposits}
            />
            <Route
              path="/transactions/withdraws"
              component={TransactionWithdraws}
            />
            <Route path="/transactions/swaps" component={TransactionSwaps} />
            <Route
              path="/transactions/changes"
              component={TransactionChanges}
            />
            <Route
              path="/settings/advertisement-position"
              component={Positions}
            />
            <Route path="/settings/careers" component={Careers} />
            <Route path="/settings/business-type" component={BusinessType} />
            <Route path="/settings/category-field" component={CategoryField} />
            <Route path="/settings/stocks-price" component={StocksPrice} />
            <Route path="/settings/landing-page" component={LandingPages} />
            <Route path="/settings/affiliates" component={Affiliates} />
            <Route path="/settings/channels" component={Channels} />
            <Route path="/settings/posts" component={PostSettings} />
            <Route path="/settings/invoice" component={InvoiceSettings} />
            <Route path="/settings/systems" component={BanksAndWallets} />
            <Route path="/settings/objects" component={SettingObjects} />
            <Route path="/settings/stars" component={Stars} />
            <Route path="/settings/logos" component={Logos} />
            <Route path="/settings/teams" component={Teams} />
            <Route path="/settings/action-post" component={ActionPosts} />
            <Route path="/settings/vips" component={Vip} />
            <Route
              path="/settings/post-background"
              component={PostBackgrounds}
            />
            <Route
              path="/settings/profile-background"
              component={ProfileBackgrounds}
            />
            <Route path="/settings/templates" component={Templates} />
            <Route path="/settings/stickers" component={Stickers} />
            <Route path="/settings/hobbies" component={Hobbies} />
            <Route path="/settings/donors" component={Donors} />
            <Route path="/settings/utilities" component={Utilities} />
            <Route
              path="/settings/page-backgrounds"
              component={PageBackgrounds}
            />
            <Route path="/settings/posts-ads" component={PostsAds} />
            <Route path="/settings/families" component={Families} />

            <Route
              path="/settings/menu-categories"
              component={SettingMenuCategory}
            />
            <Route path="/settings/icon-set" component={IconSet} />
            <Route path="/settings/tooltip" component={SettingTooltip} />
            <Route path="/settings/languages" component={SettingLanguage} />
            <Route path="/settings/religion" component={Religion} />
            <Route path="/settings/category-posts" component={CategoryPost} />
            <Route path="/settings/frames" component={Frames} />
            <Route path="/settings/companions" component={Companions} />
            <Route path="/payments/deposits" component={PaymentDeposits} />
            <Route path="/payments/withdraws" component={PaymentWithdraws} />
            <Route path="/packages" component={Packages} />
            <Route path="/campaigns" component={Campaigns} />
            <Route path="/participants" component={Participants} />
            <Route path="/settings/nations" component={Nation} />
            <Route
              path="/settings/utility-categories"
              component={SettingUtilityCategory}
            />
            <Route path="/settings/menu-admin" component={MenusAdmin} />
            <Route path="/settings/menu-censor" component={MenuCensor} />
            <Route path="/settings/special-name" component={SpecialName} />
            <Route path="/settings/quotas" component={Quotas} />
            <Route
              path="/settings/category-filters"
              component={CategoryFilters}
            />
            <Route path="/settings/shop-settings" component={ShopSetting} />
            <Route path="/settings/sites" component={Sites} />

            <Route path="/report" component={Reports} />
            <Route path="/locations" component={Locations} />
            <Route path="/tasks" component={Tasks} />
            <Route path="/post-comments" component={PostComments} />
            <Route path="/shops" component={Shops} />
            <Route path="/digitals" component={Digitals} />
            <Route path="/codes" component={Codes} />

            {/* Digital Affiliate Bank */}
            <Route
              path="/setting-bank"
              component={DigitalAffiliateBankSettings}
            />
            <Route path="/user-bank" component={UserBanks} />
            <Route path="/ban-words" component={BanWords} />

            <Route path="/attribute-set" component={AttributeSet} />

            <Route path="/settings/roles" component={SettingRoles} />
            <Route path="/settings/ships" component={Ships} />
            <Route path="/settings/theme-style" component={ThemeStyles} />

            <Route
              path="/post-interact/:type"
              component={PostSettingInteract}
            />
            <Route path="/question-and-answer" component={QuestionAndAnswerS} />
            <Route
              path="/post-welcome-templates"
              component={PostWelcomeTemplates}
            />

            <Route path="/settings/product-donate" component={ProductDonate} />
            <Route path="/settings/captcha-image" component={Captcha} />
            <Route path="/settings/apply-captchas" component={ApplyCaptcha} />
            <Route
              path="/register-receive-info"
              component={RegisterReceiveInfo}
            />
            <Route path="/adv-price" component={AdvPrice} />

            <Route
              path="/users-by-type/:type"
              component={UsersByType}
            />

            <Route path="*" component={Empty} />
          </Switch>
        </div>

        <AppFooter layoutColorMode={layoutColorMode} />
      </div>

      <AppConfig
        rippleEffect={ripple}
        onRippleEffect={onRipple}
        inputStyle={inputStyle}
        onInputStyleChange={onInputStyleChange}
        layoutMode={layoutMode}
        onLayoutModeChange={onLayoutModeChange}
        layoutColorMode={layoutColorMode}
        onColorModeChange={onColorModeChange}
      />

      <CSSTransition
        classNames="layout-mask"
        timeout={{ enter: 200, exit: 200 }}
        in={mobileMenuActive}
        unmountOnExit
      >
        <div className="layout-mask p-component-overlay"></div>
      </CSSTransition>
    </div>
  );
};

export default App;
