import moment from "moment";

export const getSeverImage = (img) => {
  return `v1`;
};

export const getUrlImage = (img, rezise?: number) => {
  if (!img) return "/assets/images/VDB-64.png";

  const typeFile = img.split(".")[img.split(".").length - 1];
  const checkFileImage = () => {
    const permission_type = [
      "jpg",
      "jpeg",
      "gif",
      "png",
      "tiff",
      "bmp",
      "svg",
      "psd",
      "ai",
      "webp",
    ];
    if (permission_type.includes(typeFile.toLowerCase())) {
      return true;
    }
    return false;
  };

  let newImg = img.replace("uploads/", "/");

  if (checkFileImage()) {
    newImg = newImg.replace(`.${typeFile}`, `--${rezise || 1000}.${typeFile}`);
  }

  if (
    String(newImg).includes("http://") ||
    String(newImg).includes("https://")
  ) {
    return newImg;
  }
  return `${
    process.env.REACT_APP_FILE || "https://file.vdiarybook.com"
  }/api/files/${newImg}`;
};

export function copyText(text: string) {
  return navigator.clipboard.writeText(text);
}

export const formatNumber = (number, digit = 2) => {
  return new Intl.NumberFormat([], { minimumFractionDigits: digit }).format(
    number
  );
};

export const formatCurrencyByType = (
  number,
  type = "en-US",
  currency = "USD",
  minimumFractionDigits = 2
) => {
  return new Intl.NumberFormat(type || [], {
    minimumFractionDigits,
    style: "currency",
    currency,
  }).format(number);
};

export const formatLocalTime = (data) => {
  return new Date(data).toLocaleString();
};

export const showToast = (toast, type = "success", messages = "") => {
  let summary = "Successful";

  switch (type) {
    case "success":
      summary = "Success Message";
      break;
    case "info":
      summary = "Info Message";
      break;
    case "warn":
      summary = "Warn Message";
      break;
    case "error":
      summary = "Error Message";
      break;
    default:
      break;
  }

  toast.current.show({
    severity: type,
    summary: summary,
    detail: messages,
    life: 2000,
  });
};

export const checkIncludeString = (contain, review) => {
  return contain.toLowerCase().includes(review.toLowerCase());
};

export const compareDate = (date1, date2) => {
  return new Date(date1).getTime() - new Date(date2).getTime();
};

export const getUrlHash = (hash) => {
  return `${
    process.env.REACT_APP_VZX_SCAN || "https://block.Vdiarybook.com"
  }/tx/${hash}`;
};

export const getFromTo = (data) => {
  return `${(data || "").slice(0, 5)}...${data.slice(
    data.length - 3,
    data.length
  )}`;
};

export const convertObjectToListValue = (object: {}) => {
  return Object.keys(object).map((k) => object[k]);
};

export const getCategoryName = (group_category: any, group_key: string) => {
  if (group_category && group_category.length > 0) {
    let group = group_category.find((e) => e.group === group_key);
    return group ? group.name : "";
  }
};

export const getStringLimit = (name: string, limit = 10) => {
  if (name.length > limit) return `${String(name).slice(0, limit)}...`;
  return name;
};

export const selectEmpty = { id: "-1", label: "Select", key: "-1" };

export const downloadFile = async (filePath: string, fileName?: string) => {
  const response = await fetch(
    `${
      process.env.REACT_APP_FILE || "https://file.vdiarybook.com"
    }/api/files/${filePath}`
  );
  const blob = await response.blob();
  const url = URL.createObjectURL(blob);

  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.download = String(fileName || "download.txt");
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  URL.revokeObjectURL(url);
};

export const getDateDirectory = () => {
  return moment(new Date()).format("YYYYMMDD");
};

export const checkSrcIsVideo = (src: any) => {
  const type = src?.split(".")[src.split(".").length - 1];
  switch (type?.toLowerCase()) {
    case "m4v":
    case "avi":
    case "mpg":
    case "mp4":
    case "mov":
    case "quicktime":
      // etc
      return true;
  }
  return false;
};

export const getStartAndEndDate = (numberDays = 7) => {
  const endDate = new Date();
  const startDate = new Date(
    endDate.getTime() - numberDays * 24 * 60 * 60 * 1000
  );
  return {
    startDate,
    endDate,
  };
};

export const getFileExtensionViaLink = (url?: string) => {
  if (url) {
    const url_splited = url.split("/");
    const name_file_splited = url_splited[url_splited.length - 1].split(".");
    if (name_file_splited.length > 1) {
      return name_file_splited[name_file_splited.length - 1];
    }
    return null;
  }
  return null;
};

export const convertNumberToVnd = (amount: number) => {
  return `₫${formatNumber(amount)}`;
};

export const getCategories = (data) => {
  let treeData;
  const mapData = (list: any) => {
    const dataChil = list.map((item: any) => chil(item));
    return dataChil.length > 0 ? dataChil : null;
  };
  const chil = (ct: any) => {
    const chilData = data.filter(
      (category) => String(category.parent_id) === String(ct._id)
    );
    return {
      ...ct,
      key: ct._id,
      label: ct.name,
      children: mapData(chilData),
    };
  };

  treeData = data.filter((ct) => !Boolean(ct.parent_id)).map((el) => chil(el));
  return treeData;
};
