/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useContext,
} from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import Service from "services/categories";
import { Dropdown } from "primereact/dropdown";
import VUpload from "components/v-upload";
import { CATEGORY_STATUS } from "utils/enum";
import { InputNumber } from "primereact/inputnumber";
import { MasterContext } from "contexts/MasterContext";
import { MultiSelect } from "primereact/multiselect";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading, groups, categories } =
    props;
  const emptyAdditionalData = {
    enable: false,
    name: "",
    key: "",
    group: "",
    icon: "",
    status: CATEGORY_STATUS.ENABLE,
    attribute_id: null,
  };
  const emptyData = {
    name: "",
    key: "",
    group: "",
    description: "",
    parent_id: null,
    icon: "",
    status: CATEGORY_STATUS.ENABLE,
    order: null,
    attribute_id: null,
    url_active: null,
    sites: null,
  };

  const [details, setDetails] = useState(emptyData);
  const [additionalData, setAdditionalData] = useState(emptyAdditionalData);
  const { attributeSets, sites } = useContext(MasterContext);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
    if (data && data.additionalDataForm)
      setAdditionalData({
        ...emptyAdditionalData,
        ...data.additionalDataForm,
      });
  }, [data]);

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await Service.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            additionalDataForm: additionalData,
          },
        });
      } else
        await Service.create({
          body: {
            ...details,
            additionalDataForm: additionalData,
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12 ">
        <label htmlFor="name">Sites</label>
        <MultiSelect
          filter
          value={details.sites}
          options={sites}
          optionLabel="name"
          optionValue="domain"
          onChange={(e) => onChange("sites", e.value)}
        />
      </div>

      <div className="field col-12">
        <label htmlFor="name">Parent</label>
        <Dropdown
          value={details.parent_id}
          options={categories}
          optionLabel="name"
          optionValue="_id"
          filter
          showClear
          onChange={(e) => onChange("parent_id", e.value)}
        />
      </div>
      <div className="field col-6">
        <label htmlFor="name">Category Group</label>
        <Dropdown
          filter
          value={details.group}
          options={groups}
          optionLabel="name"
          optionValue="key"
          onChange={(e) => onChange("group", e.value)}
        />
      </div>
      <div className="field col-6">
        <label htmlFor="attribute">Attribute</label>
        <Dropdown
          value={details.attribute_id}
          options={attributeSets?.map((k) => ({
            label: k.title,
            value: k._id,
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("attribute_id", e.value)}
        />
      </div>
      <div className="field col-6">
        <label htmlFor="name">Key</label>
        <InputText
          id="key"
          value={details.key}
          onChange={(e) => onChange("key", e.target.value)}
          required
          autoFocus
        />
      </div>
      <div className="field col-6">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange("name", e.target.value)}
          required
          autoFocus
        />
      </div>
      {details.group === "banner_page" && (
        <div className="field col-6">
          <label htmlFor="name">Url active</label>
          <InputText
            id="key"
            value={details.url_active}
            onChange={(e) => onChange("url_active", e.target.value)}
            required
            autoFocus
          />
        </div>
      )}
      <div className="field col-12">
        <label>Icon</label>
        <div className="mt-2">
          <VUpload
            urlFile={details.icon}
            setUrlFile={(file) => onChange("icon", file)}
          />
        </div>
      </div>
      <div className="field col-6">
        <label htmlFor="name">Order</label>
        <InputNumber
          id="name"
          value={details.order}
          onChange={(e) => onChange("order", e.value)}
        />
      </div>
      <div className="field col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          value={details.status}
          options={Object.keys(CATEGORY_STATUS).map((k) => ({
            label: k,
            value: CATEGORY_STATUS[k],
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("status", e.value)}
        />
      </div>
      <div className="field col-12">
        <label htmlFor="key">Description</label>
        <InputText
          id="key"
          value={details.description}
          onChange={(e) => onChange("description", e.target.value)}
          required
          autoFocus
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
