import { useState, createContext } from "react";
import CareerService from "services/careers";
import LocationService from "services/locations";
import SettingService from "services/settings";
import StockService from "services/stocks";
import SystemService from "services/systems";
import UserService from "services/users";
import MenusService from "services/menus";
import { LOCATION_TYPES, MENU_ROLE } from "utils/enum";
import NationService from "services/nations";
import ReligionService from "services/religions";
import CategoryFieldService from "services/category-field";
import AttributeSetService from "services/attribute-set";
import FamilyService from "services/families";
import VoucherService from "services/vouchers";
import PostService from "services/posts";
import CategoryService from "services/categories";
import CaptchaQuestionervice from "services/question-and-answers";

type MasterContext = {
  roles?: any;
  auth?: any;
  users?: any;
  stocks?: any;
  getUsers?: any;
  getStocks?: any;
  setAuth?: any;
  notifyPending: any;
  getNotifyPending: any;
  objects: any;
  getObjects: any;
  languages?: any;
  getLanguages?: any;
  careers?: any;
  getCareers?: any;
  locations?: any;
  getLocations?: any;
  menus?: any;
  getMenus?: any;
  getNations?: any;
  getReligions?: any;
  nations?: any;
  religions?: any;
  families?: any;
  minigames?: any;
  categoryFields?: any;
  getCategoryFields?: any;
  attributeSets?: any;
  getAttributeSets?: any;
  getFamilies: any;
  getMinigames?: any;
  ages?: any;
  getAges?: any;
  categories?: any;
  getCategories?: any;
  getRoles?: any;
  captchaQuestion?: any;
  getCaptchaQuestion?: any;
  sites: any;
  getSites: any;
  productCategories: any;
  getProductCategories: any;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MasterContext = createContext<MasterContext>({} as MasterContext);

export const MasterProvider = (props: any) => {
  const [users, setUsers] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [auth, setAuth] = useState(null);
  const [objects, setObjects] = useState([]);
  const [notifyPending, setNotifyPending] = useState({});
  const [languages, setLanguages] = useState([]);
  const [careers, setCareers] = useState([]);
  const [nations, setNations] = useState([]);
  const [religions, setReligions] = useState([]);
  const [locations, setLocations] = useState([]);
  const [menus, setMenus] = useState([]);
  const [categoryFields, setCategoryFields] = useState([]);
  const [attributeSets, setAttributeSets] = useState([]);
  const [families, setFamilies] = useState([]);
  const [minigames, setMinigames] = useState([]);
  const [ages, setAges] = useState([]);
  const [categories, setCategories] = useState([]);
  const [roles, setRoles] = useState({});
  const [captchaQuestion, setCaptchaQuestion] = useState([]);
  const [sites, setSites] = useState([]);
  const [productCategories, setProductCategories] = useState([]);

  const getCaptchaQuestion = () => {
    CaptchaQuestionervice.findAll({
      query: {
        is_captcha: true,
      },
    }).then((res: any) => {
      if (res) {
        setCaptchaQuestion(res);
      }
    });
  };

  const getStocks = () => {
    StockService.getAllCryptos().then((res: any) => {
      if (res && res.cryptos) {
        const _stocks = res.cryptos.map((s) => ({
          ...s,
          fullName: `${s.name} (${String(s.symbol).toUpperCase()})`,
        }));
        setStocks(_stocks);
      }
    });
  };

  const getAttributeSets = () => {
    AttributeSetService.findAll().then((res: any) => {
      if (res) {
        setAttributeSets(res);
      }
    });
  };

  const getNotifyPending = () => {
    SystemService.getCountPending().then((res: any) => {
      if (res && res.data) setNotifyPending(res.data);
    });
  };

  const getObjects = () => {
    SettingService.getSettingsByName({
      params: {
        name: "objects",
      },
    }).then((res: any) => {
      if (res && res.setting) {
        setObjects(res.setting.value);
      }
    });
  };

  const getCareers = () => {
    CareerService.getAll().then((res: any) => {
      setCareers(res || []);
    });
  };

  const getFamilies = () => {
    FamilyService.getAll().then((res: any) => {
      setFamilies(res || []);
    });
  };

  const getCategoryFields = () => {
    CategoryFieldService.getAll().then((res: any) => {
      setCategoryFields(res || []);
    });
  };

  const getNations = () => {
    NationService.getAll().then((res: any) => {
      setNations(res || []);
    });
  };

  const getReligions = () => {
    ReligionService.getAll().then((res: any) => {
      setReligions(res || []);
    });
  };

  const getLocations = () => {
    LocationService.getAll().then((res: any) => {
      setLocations(res || []);
    });
  };

  const getAges = async () => {
    try {
      const agesData: any = await PostService.getAgesForVideoPost();
      setAges(agesData.sort((a: any, b: any) => a?.order - b?.order));
    } catch (error) {}
  };

  const getCategories = async () => {
    try {
      const res: any = await CategoryService.getProductCategoryNormal({
        query: {
          get_all: 1,
          is_root: 1
        },
      });
      if (res) {
        setCategories(res);
      }
    } catch (error) {}
  };

  const getLanguages = () => {
    LocationService.getByTypeAndParent({
      query: {
        type: LOCATION_TYPES.COUNTRY,
      },
    }).then((res: any) => {
      res.forEach((element) => {
        element.key = element?.keyword;
      });
      setLanguages(res);
    });
  };

  const getUsers = () => {
    UserService.getUsers({
      query: {
        search: "",
      },
    }).then((res: any) => {
      if (res && res.users) setUsers(res.users);
    });
  };

  const getMenus = (userId: string) => {
    MenusService.getTreeMenu({
      params: { role: MENU_ROLE.ADMIN },
      query: {
        userId,
      },
    }).then((res: any) => {
      setMenus(res);
    });
  };

  const getMinigames = () => {
    VoucherService.getMinigames().then((res: any) => {
      setMinigames(res);
    });
  };

  const getRoles = async () => {
    const res: any = await SettingService.getSettingsByName({
      params: {
        name: "roles",
      },
    });
    if (res) {
      const { setting } = res;
      if (setting) {
        const value = setting["value"] ? setting["value"] : {};
        setRoles(value);
      }
    }
  };

  const getSites = async () => {
    const res: any = await SettingService.getSettingsByName({
      params: {
        name: "sites",
      },
    });
    if (res) {
      const { setting } = res;
      if (setting) {
        const value = setting["value"] ? setting["value"] : {};
        setSites(value);
      }
    }
  };

  const getProductCategories = async () => {
    try {
      const res: any = await CategoryService.getProductCategoryNormal({
        query: {
          get_all: true,
        },
      });
      if (res) {
        setProductCategories(res);
      }
    } catch (error) {}
  };

  return (
    <MasterContext.Provider
      value={{
        careers,
        auth,
        users,
        stocks,
        notifyPending,
        objects,
        languages,
        locations,
        menus,
        nations,
        religions,
        categoryFields,
        attributeSets,
        families,
        minigames,
        ages,
        categories,
        roles,
        captchaQuestion,
        sites,
        productCategories,
        getSites,
        getLocations,
        getCareers,
        getLanguages,
        getObjects,
        getStocks,
        getUsers,
        setAuth,
        getNotifyPending,
        getMenus,
        getNations,
        getReligions,
        getCategoryFields,
        getAttributeSets,
        getFamilies,
        getMinigames,
        getAges,
        getCategories,
        getRoles,
        getCaptchaQuestion,
        getProductCategories,
      }}
    >
      {/* <Toast ref={toast} /> */}
      {props.children}
    </MasterContext.Provider>
  );
};
